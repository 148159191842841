<template>
  <div class="send-email">
    <modal-header-with-previous
      class="modal-header-with-previous"
      current-modal-name="send-with-email-modal"
      previous-modal-name="invite-patient-modal"
    >
      <span slot="header-title" class="modal-header-with-previous__title">
        {{ $t('dashboard.label.sendEmail') }}
      </span>
    </modal-header-with-previous>
    <main class="send-email__message-template">
      <message-template
        type="EMAIL"
        :template-errors="templateErrors"
        @send-template="sendEmailTemplate"
      >
        <template slot="header-content">
          <div class="send-email__email-list email-list">
            <div class="send-email__email-list__title flex--align-center label-text">
              {{ $t('dashboard.label.to') }}
              <div
                v-tooltip="{
                  content: $t('dashboard.label.addMultipleEmails'),
                  placement: 'right-start',
                  trigger: tooltipTrigger
                }"
                class="icon-info custom-icon"
              ></div>
            </div>
            <tag-selector
              :tags="emails"
              :reg-exp="emailRegExp"
              :error-message="$t('dashboard.warning.provideValidEmail')"
              :valid="isEmailListValid"
              :validation-error-message="$t('dashboard.warning.requiredField')"
              @add-tag="addEmail"
              @delete-tag="deleteEmail"
            >
              <span slot="tag-icon" class="fas fa-envelope tag-icon mr10"></span>
            </tag-selector>
          </div>

          <dashboard-constraint
            v-model="patientEmailAgreement"
            name="patientEmailAgreement"
            class="email-list__constraint"
            :constraint-text="$t('dashboard.consent.sendEmail.agreeToMarketingOffers')"
            :hint-text="$t('dashboard.consent.sendEmailHint.agreeToMarketingOffers')"
            required
          />

          <div class="divider mb14 otherXS"></div>
        </template>
        <div slot="send-button" slot-scope="{ sendTemplate }">
          <button class="send-email-button rounded-button-orange" @click="sendTemplate">
            {{ $t('dashboard.action.send') }}
          </button>
        </div>
      </message-template>
    </main>

    <invite-template-sent-modal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty, isEqual } from 'lodash';

import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import MessageTemplate from '@/modules/dashboard/components/dashboard/common/MessageTemplate';
import TagSelector from '@/modules/dashboard/components/common/TagSelector';
import InviteTemplateSentModal from '@/modules/dashboard/components/dashboard/invite-patient/InviteTemplateSentModal';
import DashboardConstraint from '@/modules/dashboard/components/dashboard/common/DashboardConstraint';

import { sendMarketingTemplateInvites } from '@/modules/dashboard/api';
import { isErrorResponse } from '@/api/selectors';

import {
  getTemplateErrorsFromVeeValidationErrors,
  hasTemplateError,
  isMobileDevice
} from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { VALID_EMAIL_REG_EXP } from '@/constants';
import { MESSAGE_TEMPLATE_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'SendWithEmail',
  components: {
    DashboardConstraint,
    InviteTemplateSentModal,
    TagSelector,
    MessageTemplate,
    ModalHeaderWithPrevious
  },
  data() {
    return {
      emails: [],
      isEmailListValid: true,
      emailRegExp: VALID_EMAIL_REG_EXP,
      patientEmailAgreement: false,
      templateErrors: {}
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    }),
    tooltipTrigger() {
      return isMobileDevice() ? 'click' : 'hover';
    }
  },
  methods: {
    addEmail(email) {
      this.emails.push(email);
      this.isEmailListValid = true;
    },
    deleteEmail(emailIndex) {
      this.emails.splice(emailIndex, 1);
    },
    async sendEmailTemplate(messageTemplateDto) {
      await this.$validator.validate();

      this.templateErrors = getTemplateErrorsFromVeeValidationErrors(this.errors);

      if (isEmpty(this.emails)) {
        this.isEmailListValid = false;

        return;
      }

      this.isEmailListValid = true;

      if (hasTemplateError(this.templateErrors)) {
        return;
      }

      const response = await sendMarketingTemplateInvites(this.userId, {
        messageTemplateDto,
        destinations: this.emails,
        wayOfSend: MESSAGE_TEMPLATE_TYPES.EMAIL
      });
      const { destinations } = response.data;

      const isInvitationSent = !isEqual(this.emails, destinations);

      if (!isErrorResponse(response)) {
        this.$modal.hide('send-with-email-modal');

        this.$modal.show('invite-template-sent-modal', {
          isInvitationSent,
          unsubscribedEmails: destinations
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/css/common/icons.css';
@import '../../../../../../assets/scss/common/text';
@import '../../../../../../assets/scss/modals/modal-header-with-previous-shared';
@import '../../../../../../assets/scss/invite-patients/invite-patient-shared';

.send-email {
  font-size: 15px;
  line-height: 17px;
  color: $main-title-color;

  &-button {
    height: 36px;
  }

  &__email-list {
    margin-bottom: 20px;

    &__title {
      margin-bottom: 15px;
    }
  }
}

.email-list {
  &__constraint {
    margin-bottom: 20px;
  }
}

.tag-icon {
  color: $icon-grey-color;
}

@media (max-width: 767px) {
  .send-email {
    font-size: 13px;
    line-height: 15px;
    background-color: $white;
    min-height: 100vh;

    &__email-list {
      margin-bottom: 10px;

      &__title {
        margin-bottom: 4px;
      }
    }

    .email-list {
      &__constraint {
        margin-bottom: 10px;
      }
    }

    /deep/ .label-text {
      margin-bottom: 4px;
    }

    /deep/ .rounded-input {
      margin-bottom: 0;
    }
  }

  .send-email-button {
    height: 32px;
    font-size: 16px;
    line-height: 19px;
  }
}
</style>
